import './BioProfile.css';
import pic_profile from '../../../../Static/img/SVG/profile_pic.svg';

const BioProfile = () => {

    return (
        <section className='bio-container'>
            <div className='bio-text-container'>
                <h1 className='bio-greetings'>Hello_World!</h1>
                <h2 className='bio-name' >I’m Cahaya (KayRama) Ramadhan </h2>
                <h3 className='bio-position'>I as Software Engineer and iOS Developer</h3>
            </div>
            <div className='bio-img-container'>
                <img className="logo_profile" alt="logo" src={pic_profile}/>
            </div> 
        </section>
        
    );
  }
  
  export default BioProfile;