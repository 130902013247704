import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './Static/css/App.css';
import "./fonts/Rock\ Jack\ Writing.woff";
import "./fonts/Qaz.ttf";
import "./fonts/QazItalic.ttf";

import Resume from "./Pages/Resume/Resume";
import Home from "./Pages/Home/Home";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route index element={<Home />} />
          <Route exact path="/resume" element={<Resume />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
