import './Home.css';

import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import BioProfile from '../../Components/Sections/Biography/BioProfile/BioProfile';
import AboutMe from '../../Components/Sections/Biography/AboutMe/AboutMe';
import JourneysView from '../../Components/Sections/Journeys/JourneyView';
import SkillView from '../../Components/Sections/Growth/GrowthView';

const Home = () => {
  document.title = "Dashboard | KayRama"

  return (
    <div>
      <Header/>
      <BioProfile/>
      <AboutMe />
      <JourneysView />
      {/* <SkillView /> */}
      {/* <div className='bg-white justify-content grid place-items-center h-screen'>
          <h1 className="text-3xl text-gray font-bold ">
              The Website Still Cooming Soon (=ʘᆽʘ=)∫
          </h1>
      </div> */}
      {/* <Footer /> */}
    </div>
  )
}

export default Home;