import './Resume.css';

const Resume = () => {
  return (
    <div className='bg-blue justify-content grid place-items-center h-screen'>
      <h1 className="text-3xl  font-bold">
        Resume page
      </h1>
    </div>
    
  )
}

export default Resume;