import './AboutMe.css';

const AboutMe = () => {

    return (
        <section className='aboutme-container'>
            <div className='line-container'/>
            <div className='aboutme-text-container'>
                <h1 className='aboutme-title'>About Me</h1>
                <p className='aboutme-content'>I’m an iOS Developer and Software Engineer born in Jakarta who wants to make the world better with my skills.</p>
                <p className='aboutme-content'>My passion is making a product that has a good impact.</p>
                <p className='aboutme-content'>Furthermore, I want to be an expert in Software Development.</p>
            </div>
            <div className='line-container'/>
        </section>
    );
  }
  
  export default AboutMe;