import './JourneyView.css';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ws_box from '../../../Static/img/PNG/Webstaff.png';
import binus_box from '../../../Static/img/PNG/Binus.png';
import binus_univ from '../../../Static/img/PNG/BinusUniv.png';
import ada from '../../../Static/img/PNG/ADA.png';
import ecc from '../../../Static/img/PNG/ECC.png';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const JourneyView = () => {

    const [selectedId, setSelectedId] = useState(null)

    const JourneyData = [
        {
            exp_id: '1',
            exp_icon: binus_box,
        },
        {
            exp_id: '2',
            exp_icon: ws_box,
        },
    ]
 
    // {items.map(item => (
    // <motion.div layoutId={item.id} onClick={() => setSelectedId(item.id)}>
    //     <motion.h5>{item.subtitle}</motion.h5>
    //     <motion.h2>{item.title}</motion.h2>
    // </motion.div>
    // ))}

    // <AnimatePresence>
    // 
    // </AnimatePresence>

    return (
        <section className='journey'>
            <div className='journey-container'>
                <h1 className='journey-title'>Journeys</h1>
                <div className='journey-list'>
                    <VerticalTimeline
                        lineColor='#71b1e1'
                    >
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#fff', color:  '#71b1e1', fontSize: '29px', border: '5px solid #71b1e1'}}
                            contentArrowStyle={{ borderRight: '20px solid  #71b1e1' }}
                            date="August 2017"
                            iconStyle={{ background: '#71b1e1', color: '#fff', border: '5px solid #71b1e1'}}
                            icon={<img src={binus_univ}/>}
                        >
                            <div className='timeline-container'>
                                <h3 className="timeline-h3">Starting study in Binus Online Learning</h3>
                                <h4 className="timeline-h4"> Take a study Computer Science major.</h4>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#fff', color:  '#71b1e1', fontSize: '29px', border: '5px solid #71b1e1'}}
                            contentArrowStyle={{ borderRight: '20px solid  #71b1e1' }}
                            date="June 2018"
                            iconStyle={{ background: '#71b1e1', color: '#fff', border: '5px solid #71b1e1'}}
                            icon={<img src={ada}/>}
                        >
                            <div className='timeline-container'>
                                <h3 className="timeline-h3">Get a Scholarship at Apple Developer Academy@Binus</h3>
                                <h4 className="timeline-h4">○ Study the iOS Development from beginning until upload on AppStore.</h4>
                                <h4 className="timeline-h4">○ Creating the apps with method Challenge Based Learning.</h4>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#fff', color:  '#71b1e1', fontSize: '29px', border: '5px solid #71b1e1'}}
                            contentArrowStyle={{ borderRight: '20px solid  #71b1e1' }}
                            date="March 2019"
                            iconStyle={{ background: '#71b1e1', color: '#fff', border: '5px solid #71b1e1'}}
                            icon={<img src={ada}/>}
                        >
                            <div className='timeline-container'>
                                <h3 className="timeline-h3">Finish the Scholarship at Apple Developer Academy@Binus</h3>
                                <h4 className="timeline-h4">Get a many experiences from this program, both hard skill and soft skill.</h4>
                            </div>
                        </VerticalTimelineElement>
                        
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#fff', color:  '#71b1e1', fontSize: '29px', border: '5px solid #71b1e1'}}
                            contentArrowStyle={{ borderRight: '20px solid  #71b1e1' }}
                            date="April 2019"
                            iconStyle={{ background: '#71b1e1', color: '#fff', border: '5px solid #71b1e1'}}
                            icon={<img src={binus_box}/>}
                        >   
                            <div className='timeline-container'>
                                <h3 className="timeline-h3">Get a job at Binus University</h3>
                                <h4 className="timeline-h4">Get a job as iOS Developer to creating the Universty alumne apps.</h4>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#fff', color:  '#71b1e1', fontSize: '29px', border: '5px solid #71b1e1'}}
                            contentArrowStyle={{ borderRight: '20px solid  #71b1e1' }}
                            date="March 2021"
                            iconStyle={{ background: '#71b1e1', color: '#fff', border: '5px solid #71b1e1'}}
                            icon={<img src={ecc}/>}
                        >
                            <div className='timeline-container'>
                                <h3 className="timeline-h3">Acquire a scholarship to pursue Japanese language studies.</h3>
                                <h4 className="timeline-h4">I have been awarded a scholarship by Disco Company to undertake online Japanese language classes, progressing from N5 to N3 levels at ECC Nihongo.</h4>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#fff', color:  '#71b1e1', fontSize: '29px', border: '5px solid #71b1e1'}}
                            contentArrowStyle={{ borderRight: '20px solid  #71b1e1' }}
                            date="April 2021"
                            iconStyle={{ background: '#71b1e1', color: '#fff', border: '5px solid #71b1e1'}}
                            icon={<img src={binus_box}/>}
                        >
                            <div className='timeline-container'>
                                <h3 className="timeline-h3">Finish work at Binus University</h3>
                                <h4 className="timeline-h4">○ Making apps for Binus Alumni, Binus Career JS, and Binus Career Company using:<br />
- Cocopods, Alamofire, BSImagePicker, SwiftyJSON, UIKi, CoreData. <br />
- Using Storyboard and XIB - Design some logos with Sketch. <br />
○ I’m working on this project as a team and I got the responsibility to be of creating this iOS application from beginning to AppStore.</h4>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#fff', color:  '#71b1e1', fontSize: '29px', border: '5px solid #71b1e1'}}
                            contentArrowStyle={{ borderRight: '20px solid  #71b1e1' }}
                            date="December 2021"
                            iconStyle={{ background: '#71b1e1', color: '#fff', border: '5px solid #71b1e1'}}
                            icon={<img src={binus_univ}/>}
                        >
                            <div className='timeline-container'>
                                <h3 className="timeline-h3">Finish study in Binus Universty</h3>
                                <h4 className="timeline-h4"> Get the Computer Science Bachelor Degree</h4>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#fff', color:  '#71b1e1', fontSize: '29px', border: '5px solid #71b1e1'}}
                            contentArrowStyle={{ borderRight: '20px solid  #71b1e1' }}
                            date="September 2021"
                            iconStyle={{ background: '#71b1e1', color: '#fff', border: '5px solid #71b1e1'}}
                            icon={<img src={ecc}/>}
                        >
                            <div className='timeline-container'>
                                <h3 className="timeline-h3">Finish the Scholarship Japanese Language</h3>
                                <h4 className="timeline-h4">I get many experiences on this class mainly I can speak Japanese converstations, Business Maners, Culture and practice for interview.</h4>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#fff', color:  '#71b1e1', fontSize: '29px', border: '5px solid #71b1e1'}}
                            contentArrowStyle={{ borderRight: '20px solid  #71b1e1' }}
                            date="February 2022 - Present"
                            iconStyle={{ background: '#71b1e1', color: '#fff', border: '5px solid #71b1e1'}}
                            icon={<img src={ws_box}/>}
                        >   
                            <h3 className="timeline-h3">Get a job at WebStaff</h3>
                            <h4 className="timeline-h4">Get a job Software Engineer and move to Japan</h4>
                        </VerticalTimelineElement>
                    </VerticalTimeline>
                </div>
            </div>
        </section>
    );
  }
  
  export default JourneyView;