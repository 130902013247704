import header from './Header.css';
import "../../fonts/Rock\ Jack\ Writing.woff";
import kayrama_lg from '../../Static/img/SVG/1_RGB-08.svg';

const Header = () => {

    return (
        <header className='nav_header'>
            <img className="logo_img" alt="logo" src={kayrama_lg}></img>
            <nav className='nav'>
                <a href="blog.html" className="nav_button">Profile</a>
                {/* <a href="#" className="nav_button">Experiences</a> */}
                <a href="#" className="nav_button">Journeys</a>
            </nav>
        </header>
    );
  }
  
  export default Header;